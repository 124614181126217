@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-800;
  }
  button,
  input,
  select,
  textarea {
    @apply focus:outline-none !important;
    @apply focus:ring-0 !important;
  }
}

@layer components {
  .avatar {
    @apply inline-block rounded-full;
  }
  .avatar-default {
    @apply inline-block align-middle rounded-full overflow-hidden bg-gray-100;
  }
  .button {
    @apply px-4 h-12 rounded-lg font-bold;
  }
  .card {
    @apply flex flex-col rounded-lg border border-gray-200 divide-y divide-gray-200 shadow;
  }
  .checkbox {
    @apply wh-5 rounded-none text-gray-800
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .dropdown {
    @apply relative w-max;
  }
  .dropdown-view {
    @apply absolute mt-10 w-max shadow-lg ring-1 ring-black ring-opacity-5 bg-white z-10
           focus:outline-none transition transform origin-top;
  }
  .h1 {
    @apply text-center font-bold text-3xl  md:text-4xl text-gray-800 md:leading-relaxed leading-normal;
  }
  .label {
    @apply font-bold text-sm text-gray-800;
  }
  .label-col {
    @apply flex flex-col space-y-1;
  }
  .label-row {
    @apply flex items-center space-x-2;
  }
  .menu {
    @apply flex flex-col py-1;
  }
  .menu-item {
    @apply px-4 py-2 text-left text-sm text-gray-600 hover:font-bold hover:text-gray-800;
  }
  .pagination {
    @apply flex justify-between items-center px-6 py-3;
  }
  .pagination-label {
    @apply text-sm text-gray-700;
  }
  .pagination-nav {
    @apply flex shadow-sm -space-x-px;
  }
  .pagination-nav-button {
    @apply grid place-items-center p-2 min-w-10 border text-sm font-medium;
  }
  .radio {
    @apply wh-4 text-brand-1
           focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1;
  }
  .section {
    @apply flex flex-col px-4 sm:px-6 md:px-8 mx-auto py-10 md:py-20 max-w-screen-xl;
  }
  .select {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .sidebar {
    @apply flex flex-col w-64 border-r border-gray-200;
  }
  .sidebar-title {
    @apply flex items-center px-4 h-16 font-bold text-2xl text-gray-800;
  }
  .sidebar-menu {
    @apply flex-grow flex flex-col space-y-1 p-2 overflow-y-auto;
  }
  .sidebar-menu-item {
    @apply p-2 rounded-md text-sm font-medium flex items-center;
  }
  .table {
    @apply divide-y divide-gray-200;
  }
  .table-head {
    @apply bg-gray-50;
  }
  .table-body {
    @apply divide-y divide-gray-200;
  }
  .table-th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }
  .table-td {
    @apply px-6 py-4 max-w-xs text-left text-sm text-gray-500 truncate;
  }
  .tabs {
    @apply flex justify-center space-x-4;
  }
  .textarea {
    @apply px-4 py-2 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .textfield {
    @apply px-4 h-12 border border-gray-200 rounded-md placeholder-gray-400
           disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0;
  }
  .toggle {
    @apply relative inline-flex flex-shrink-0 w-11 h-6 border-2 border-transparent rounded-full
           cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1
           transition-colors ease-in-out duration-200;
  }
  .toggle-slider {
    @apply pointer-events-none inline-block w-5 h-5 rounded-full bg-white shadow ring-0
           transition ease-in-out duration-200 transform;
  }
  .fr-element {
    font-size: 16px !important;
  }
}
